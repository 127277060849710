import React from "react";
import { graphql, StaticQuery } from "gatsby";
import * as ds from "../uiLibrary";
import styled from "styled-components";

const StyledDate = styled(ds.Eyebrow)`
  color: ${ds.colors.typography.darkTertiary};
  font-size: ${ds.typeScale.t3};
`

class MediaRoll extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMediaJson.edges

    return (
      <div>
        {posts &&
          posts.map(({ node: post }) => (
            <div key={post.id}>
              <ds.StyledLink to={post.fields.slug} >
                <ds.ClickableCard width={["80%", "90%", "100%"]}>
                  <ds.CenteredImg src={post.featuredImage} maxWidth={["100%", "50%", "25%"]} />
                  <ds.H2 color={ds.colors.typography.darkPrimary} mb={ds.spacing.s3}>
                    {post.title}
                  </ds.H2>
                  <ds.P color={ds.colors.typography.darkPrimary} fontSize={ds.typeScale.t4}>{post.fields.excerpt}</ds.P>
                  <ds.P color={ds.colors.brandRed} fontSize={ds.typeScale.t4}>Keep Reading →</ds.P>
                  <StyledDate>Posted on: {post.date}</StyledDate>
                </ds.ClickableCard>
              </ds.StyledLink>
            </div>
          ))}
      </div>
    )
  }
}


export default () => (
  <StaticQuery
    query={graphql`
    query {
      allMediaJson(sort: {order: DESC, fields: [date]}) {
        edges {
          node {
            id
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage
            body
            fields {
              slug
              excerpt
            }
          }
        }
      }
    }
`}
    render={(data, count) => <MediaRoll data={data} count={count} />}
  />
)