import React from 'react'
import * as ds from "../uiLibrary"
import Layout from '../components/layouts'
import MediaRoll from '../components/MediaRoll'
import { InnerPageHero } from '../components/heroes'
import tonitMedia from "../images/tonit-more-media.jpg"


export default class MoreMediaPage extends React.Component {
  render() {
    return (
      <Layout>
      <InnerPageHero url={tonitMedia} alt="Tonit Motorcycle FAQ">
        <ds.FixedWidthRow>
          <ds.Column span={8}>
            <ds.Headline fontSize={[ds.typeScale.t6, ds.typeScale.t8]}>
              Media Coverage
            </ds.Headline>
          </ds.Column>
        </ds.FixedWidthRow>
      </InnerPageHero>
      <ds.Background bg={ds.colors.white}>
        <ds.FixedWidthRow>
          <ds.Column span={12}>
            <MediaRoll/>
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
    )
  }
}